import { memo, splitProps } from '../helpers.js';
import { createRecipe, mergeRecipes } from './create-recipe.js';

const menuItemFn = /* @__PURE__ */ createRecipe('menuItem', {
  "size": "md"
}, [
  {
    "size": "sm",
    "autoHeight": true,
    "css": {
      "height": "auto"
    }
  },
  {
    "size": "md",
    "autoHeight": true,
    "css": {
      "height": "auto"
    }
  }
])

const menuItemVariantMap = {
  "autoHeight": [
    "true",
    "false"
  ],
  "size": [
    "sm",
    "md"
  ]
}

const menuItemVariantKeys = Object.keys(menuItemVariantMap)

export const menuItem = /* @__PURE__ */ Object.assign(memo(menuItemFn.recipeFn), {
  __recipe__: true,
  __name__: 'menuItem',
  __getCompoundVariantCss__: menuItemFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: menuItemVariantKeys,
  variantMap: menuItemVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, menuItemVariantKeys)
  },
  getVariantProps: menuItemFn.getVariantProps,
})